.squize.container {
    position: absolute;
    background: #fff;
    left: 104%;
    top: 44px;
    box-shadow: 0px 0px 5px #000;
    z-index: 99;
    width: auto;
    border-radius: 5px;
}
.squize .card{
    border: none;
}
.squize .card.custcard {
    margin: auto;
}
.squize .card-body{
    padding: 0px;
    margin-top: 15px;
}
.squize .btn-wrapper{
    margin-bottom: 5px;
    margin-top: 8px;
}
.squize .FooterBAck{
    margin-right: 0px;;
}
.squize .FooterBAck .card {
    border: none;
    margin: auto;
    width: 100%;
}
.squize .FooterBAck  .cp-btn-group1 .btn-wrapper:last-child .btn{
    margin-right: 0px;;
}
.squize .FooterBAck .btn-group {
    display: inline-flex;
}
.black-text{
    color: #000 !important;
}
.squize .border {
    position: absolute;
    top: 2;
    left: 15px;
    width: calc(100% - 35px);
    display: block;
    border-color: #000 !important;
    border-bottom: none !important;
}
.project-hold{
    display: inline-flex;
    flex-wrap: wrap;
    width: 600px;
}
.hide-important{
    display: none !important;
}