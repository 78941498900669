.login-overlay {
    background: radial-gradient(#2591d0, #111118) rgba(34, 34, 40, .94);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 1;
    transition: opacity 0.2s ease-in 0.4s;
}
.login-table {
    background: #2491cf;
    
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
}
.login-table tr{
    border-bottom: none;
}
.login-table td:nth-child(1){
    background: #fff;
    width: 260px;
}
.login-holder{
    padding: 10px 25px;
}
.login-provider img {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-top: -4px;
    margin-right: 10px;
}
.login-holder a{
    width: 100%;
}
.ch-logo_login{
    width: 250px !important;
}
.abcRioButton.abcRioButtonLightBlue {
    opacity: 0;
}

.navbar navbar bg-dark{
    display: none !important;
}

.login-table{
    width:700px;
}

.card-content .MuiFormControl-root{
    width:100% 
}
.card-content .MuiInput-underline:after{
    transform: scaleX(1) !important;
    border-bottom: 1px solid #ffffff !important;
}

 .MuiInputLabel-formControl{
    color:#ffffff !important;
}
.MuiInput-underline:hover{
    border-bottom:none !important;
}
#login{
    background-color: #ffffff !important;
    color: #0D47A1 !important;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    padding: 0 28px;

}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom:none !important;
}

div.Glogin > button{
    background-color: #ffffff !important;
    color: gray !important;
    font-size: 16px !important;
    width: 100% !important;
}

.yellow-text{
    color:#ffeb3b !important
}
.forgot-password{
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}
.forgot-password:hover{
    text-decoration: underline;
}
.hide-input{
    visibility: hidden !important;
}

element.style {
    width: 100%;
}
#login.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
}
ul.passwordcheck > li > span{
    font-size: 13px;
    opacity: 0.5;
}
ul.passwordcheck > li > .checklist-icon {
    width: 10px;
    height: 10px;
}
ul.passwordcheck{
    padding: 10px;
    background: #fff;
    margin-top: 10px;
    border-radius: 3px;
}