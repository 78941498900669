.cp-btn-group .btn {
  min-width: 140px;
  background: white;
  color: gray;
  margin-right: 5px;
  white-space: nowrap;
}

.btn-wrapper {
  position: relative;
}

.btn-wrapper input[type='radio'],
.btn-wrapper input[type='checkbox'] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.active + .btn {
  background-color: #343a40;
  color: white;
}

.btn img {
  height: 40px;
  width: 40px;
  max-width: 100%;
  max-height: 100%;
}

.os-list .btn {
  padding: 0px 10px;
}

.cp-btn-group1 .active1 {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.cp-btn-group1 .inactive {
  color: #fff;
  /* background-color: gray !important;
    border-color: gray !important; */
}

/* .btn-wrapper > button#cancel:hover{
  background-color: #0c6877 !important;
 } */

.cp-btn-group.btn {
  min-width: 100px;
  border-radius: 0;
  background: white;
  color: gray;
}

/* div.cord-body > div:nth-child(3){
    display: none !important;
  }  */

.custcard {
  margin-left: 48px;
  margin-right: 48px;
  margin-bottom: 8px;
  /* backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  background: rgb(244 245 247 / 75%);
}
.card{
  /* backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  background: rgb(244 245 247 / 75%);
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgb(255, 255, 255);
  border: none;
}

.FooterBAck {
  position: relative;
  text-align: right;
  margin-right: 7%;
}

.cp-btn-group1 .btn {
  min-width: 150px;
  color: white;
  margin-right: 5px;
  border: 1px solid white;
}

.FooterBAck .card {
  border: none;
}

.cancelstartmark {
  margin-right: 8px;
  font-size: 16px;
  line-height: 20px;
}

.btn-allow:disabled {
  color: #cca9a9;
  cursor: not-allowed !important;
  opacity: 0.45;
  /* background-image : url("https://cdn.test.io/assets/btn-disabled-white-28ca7cbd48c5ff19e26fbc73c1ea48d7d0471d9f2d03259bba79d06b6cc79254.png"); */
  background-image: url('https://cdn.test.io/assets/btn-disabled-petrol-970514f228500556f24b8b3768481eeeb199f61eae972b6419d2d336a02fd7f4.png');
  border-color: rgba(90, 62, 62, 0.71);
}

button#cancel:hover {
  background-color: #1d7fb9;
}

.cp-btn-group1 .btn-wrapper:hover .btn {
  background-color: #1d7fb9 !important;
}

.btn-allow.inactive:active {
  opacity: 1;
  cursor: pointer !important;
  transition: all 0.15s linear 0s;
}

.title-prompts {
  display: inline-block;
  width: 28%;
  text-align: right;
  margin-right: 15px;
  font-size: inherit;
  color: gray;
}

.testHeading {
  padding: 15px;
  text-align: center;
  height:60px
}

.custSchedule {
  color: gray;
}

#cancel {
  box-shadow: 0 0 4px rgb(37, 145, 208);
  background-color: #2591d0;
}

button.btn.btn-info.btn-allow.inactive:not(:disabled) {
  letter-spacing: 1px;
  box-shadow: 0 0 4px rgb(37, 145, 208);
  background-color: #2591d0;
}

.btn-info.btn-allow {
  background-color: #2591d0;
}

#Switch_toggle,
.Date_Pick {
  margin-left: 14px !important;
}

.headermsg {
  margin-bottom: 0rem;
  font-size: 18px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 40px !important;
  }
}

.MuiTypography-root,
.MuiTypography-h2,
.MuiTypography-subtitle1,
.MuiTypography-h2,
.MuiButton-root,
.MuiInputBase-root,
.MuiTypography-body1,
.MuiPaper-root,
.MuiTypography-h4,
.MuiButton-label,
.MuiTypography-caption {
  font-family: inherit !important;
  font-size: 16px !important;
}

.MuiPickersDatePickerRoot-toolbar.MuiToolbar-gutters
  > button:nth-child(1)
  > span.MuiButton-label
  > h6 {
  display: none !important;
}

.MuiPickersTimePickerToolbar-ampmSelection {
  flex-direction: row !important;
}

.MuiPickersTimePickerToolbar-ampmLabel {
  padding: 2px;
}

.MuiTypography-h4 {
  margin-left: 72px !important;
}

.MuiPickersCalendarHeader-switchHeader {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.MuiPickersBasePicker-pickerView {
  display: flex !important;
  max-width: 280px !important;
  min-width: 280px !important;
  min-height: 280px !important;
  overflow-x: hidden !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.MuiDialogActions-root {
  padding: 0px !important;
}

.MuiDialog-root {
  position: fixed !important;
  z-index: 1300 !important;
  right: 40% !important;
  bottom: -25% !important;
  top: 0px !important;
  left: 0px !important;
}

.MuiPickersToolbar-toolbar {
  height: 40px !important;
  background-color: #2591d0 !important;
}
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer {
  background-color: #2591d0 !important;
}
span.MuiIconButton-label > svg {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiPickersModal-dialogRoot {
  min-width: 280px !important;
}

.MuiDialogContent-root.MuiPickersModal-dialog {
  height: 320px;
  overflow: hidden;
}

.MuiPickersClock-container {
  margin: 0px !important;
}

.MuiPickersCalendar-transitionContainer {
  margin-top: 0px !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #2591d0 !important;
}

.MuiButton-label,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer {
  color: #2591d0 !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #2591d0 !important;
}

.MuiDialog-paperScrollPaper {
  left: 6% !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #2591d0 !important;
}
