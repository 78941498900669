.text-section {
  text-align: left;
}

.inputWrapper {
  height: auto;
  position: sticky; 
  bottom: 0;
background-color: transparent;
 border: none;
 box-shadow: none;
  text-align: center;
  z-index: 3;
 
}
.box-container {
  align-items: center;
  min-height: 40%;
  margin: 5px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  flex-grow: 1;
  overflow-y: auto;
 
  z-index: 1;
  /* box-shadow: none; */
}
.menu-box {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 15px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 20px;
}

.menu-box:hover {
  color: rgb(68, 68, 184);
  text-decoration: underline;
}

.avatar {
  width: 50px; /* Set your desired size */
  height: 50px; /* Set your desired size */
  border-radius: 50%; /* Make it circular */
  margin-right: 10px;
}

.content {
  flex-grow: 1;
  font-size: 14px;
}
.messageBox {
  z-index: 1;
  justify-content: center;
  max-width: 93%; /* This is "md" in Material-UI’s theme */
  width: 100%;
  
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Custom scrollbar styling */
.box-container::-webkit-scrollbar {
  background-color: #ddd;
  width: 0.6rem;
}

.box-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color:rgb(0, 158, 235);
  min-height: 9rem;
  border: solid rgb(0, 158, 235);
}

.messageBox::-webkit-scrollbar-thumb:focus,
.messageBox::-webkit-scrollbar-thumb:active,
.messageBox::-webkit-scrollbar-thumb:hover {
  background-color: #959595;
}

.messageBox::-webkit-scrollbar-corner {
  background-color: #2b2b2b;
}

.customOutlinedInput {
  /* Styling for the notched outline border */
  .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }
}

.testResultLabel {
  font-size: 18px;
  text-align: center;
  flex: 1;
}

.testResultLabel center {
  display: block; /* Ensures that <center> works properly */
}

.cardAdditionalStyling {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.boxStyling {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: clip;
  font-size: 1rem;
}
.h6Generic ul li {
  font-size: 0.9vw !important;
}
.avatarGen {
  justify-content: center;
  color: #fff;
}
.h6Generic {
  margin: 0.25vw;
  font-size: 0.9vw;
  text-align: justify;
  font-weight: 500;
}


.pBot {
  color: white;
  margin-top: 15px; /* Moved from inline style of p */
  font-size: 16px;
}



