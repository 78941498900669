.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    display: none;
    z-index: 2;
}

.backdrop.show {
    display: block;
}

.bg-white {
    z-index: 2 !important;
}

#slidout-content {
    position: fixed;
    right: 13px;
    top: -1000px;
    background: #fff;
    z-index: 3;
    transition: 300ms;
    width: 300px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

#slidout-content.show {
    top: 70px;
}

.background {
    position: relative;
    height: 200px;
    overflow: hidden;
    color: #fff;
}

.background:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.background img {
    position: absolute;
    left: 0;
    top: 0;
}

.menu {
    padding: 0;
}

.menu li {
    list-style: none;
    display: block;
    text-align: left;
    padding: 0px 20px;
    line-height: 48px;
}

.menu li:hover {
    background-color: #ddd9d9;
}

.menu li a {
    color: #000;
}
.menu li a i{
    width: 20px;
}


.menu li.hide {
    display: none;
}

.user-view {
    position: relative;
}

.user-details {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    text-align: left;
}

.user-details a {
    color: #fff;
}

.user-details .circle {
    height: 64px;
    width: 64px;
    border-radius: 50%;
}

.bg-primary {
    background: #2591d0 !important;
}

nav.navbar.navbar {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

body {
    background-color: #f8f8f8;
}

.ch-logo, .customer-logo {
    width: 140px;
}
.customer-logo{
    filter: none;
    display: none;
}
.hide{
    display: none;
}
.bg-dark .navbar-toggler{
    color: #fff;
}