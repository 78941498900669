.trends {
  margin-top: 20px !important;
  width     : 100%;
  height    : 98% !important;
  border    : 0px solid #d6dde3;
}

.iframe-hold {
  position: relative;
}

.iframe-hold:after {
  content   : " ";
  position  : absolute;
  width     : 180px;
  height    : 35px;
  bottom    : 5px;
  right     : 22px;
  z-index   : 999;
  background: #eaeaea;
}
.iframe-hold:before {
  content   : " ";
  position  : absolute;
  width     : 95%;
  height    : 35px;
  bottom    : 5px;
  left     : 20px;
  z-index   : 999;
  background: #eaeaea;
}

@media screen and (min-width: 1920px) {
  .iframe-hold:after {
    bottom    : 20px;
  }
  .iframe-hold:before {
    bottom    : 20px;
  }

}