#mystyle {
  background: inherit;
  width: 80%;
}
#mystyle1 {
  background: inherit;
}
.cut {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 13px !important;
}
.MuiTableCell-root {
  padding: 5px 8px 5px 0px !important;
  font-size: 13px !important;
  font-family: inherit !important;
  white-space: nowrap;
}
.QueueTable .MuiTableCell-root svg {
  font-size: 20px !important;
}
.MuiTableCell-head {
  font-size: 14px !important;
  /* font-weight: 800 !important; */
  font-family: inherit;
  line-height: 2.5rem !important;
}
.MuiButton-containedSecondary {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.MuiTableContainer-root{
  background: none;
}
/* .custom-container {
  max-width: 1033px;
} */
.MuiTableHead-root {
  /* box-shadow: 2px 2px 3px grey; */
  font-size: 16px !important;
  font-family: inherit;
  white-space: nowrap;
}
.MuiPaper-elevation1 {
  /* box-shadow: none !important; */
}
.MuiTableContainer-root.MuiPaper-root{
  background: none;
}
.MuiTableCell-head {
  font-weight: 800px !important;
}
.headingVst {
  padding: 15px;
  font-family: inherit;
  font-size: 18px;
}
.viewcard {
  height: 50px;
  margin-bottom: 20px;
}
.search {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9E9E9E;
  border-radius: 0;
  outline: none;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border .3s, -webkit-box-shadow .3s;
  transition: border .3s, -webkit-box-shadow .3s;
  transition: box-shadow .3s, border .3s;
  transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
  font-family: initial;
  transition: all .2s ease-in-out;
}
.QueueTable .MuiButton-label{
  font-size: 11px !important;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.MainSearch {
  text-align: right;
  padding-top: 10px;
}
.search:focus {
  border-bottom: 1px solid #343A40;
  -webkit-box-shadow: 0 1px 0 0 #343A40;
  box-shadow: 0 1px 0 0 #343A40;
}
.QueueTable .MuiSvgIcon-root {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: small !important;
}
.QueueTable .cancel_svg {
  font-size: 16px;
  margin-left: 5px;
  color: #e91f10eb!important;
}
.MuiTableRow-root {
  box-shadow: 0px 0px 1px;
  border-radius: 2px;
}
.MuiTableCell-root.MuiTableCell-head, .MuiTableCell-root.MuiTableCell-body {
  background: #fff;
}
.MuiTableCell-root.MuiTableCell-head:first-child, .MuiTableCell-root.MuiTableCell-body:first-child{
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.MuiTableCell-root.MuiTableCell-head:last-child, .MuiTableCell-root.MuiTableCell-body:last-child{
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.headermsg1 {
  margin-bottom: 0rem;
  /* margin-top: 12px; */
  font-size: 18px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.QueueTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
  /* background: #fff; */
  padding: 0px 33px 0px 33px !important;
}
.tag {
  background: #d6dde3;
  display: inline-block;
  text-align: center;
  padding: 0px 10px;
  color: #000;
  border-radius: 3px;
  vertical-align: middle;
  white-space: nowrap;
}
.env {
  background: #2591D0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 5px;
}
.queuepageimg {
  width: 30px;
  height: 30px
}
.MuiTableContainer-root {
  overflow-x: visible !important;
}
.MuiButton-root {
  padding: 6px 10px !important;
}
.btn-cust {
  color: #1976D2 !important
}
.cardNoMargin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
img.Landscape {
  transform: rotateZ(-90deg);
}
.error_queue {
    height: 20%;
    justify-content: center;
    -webkit-justify-content: center;
    color: blue;
    font-weight: 400;
}
.sorting-icon-holder{
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
    flex-direction: column;
    vertical-align: middle;
    height: 28px;
    justify-content: center;
    -webkit-justify-content: center;
}
.QueueTable .progressing-status span {
  background: #d6dde3;
  color: #000;
  padding: 0 5px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.QueueTable span.persentage {
  background: none;
  position: relative;
  vertical-align: middle;
  z-index: 1;
  margin-left: 5px;
}
.QueueTable .progressing-status i:first-child {
  position: absolute;
  background: green;
  left: 0;
  z-index: 1;
  top: 0;
  border-radius: 3px;
  color: #fff;
  overflow: hidden;
  padding-left: 5px;
  transition: .5s;
  -webkit-animation: progress 2s infinite;
  animation: progress 2s infinite;
  color: transparent;
  z-index: 0;
}
.QueueTable .progressing-status i:last-child {
  position: relative;
}
.QueueTable .progressing {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  /* font-weight: bold; */
  /* width: 125px; */
}
.pass-fail-error{
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.QueueTable .progressing-status {
  margin-bottom: 0;
  /* width: 125px; */
  display: inline-block;
}
.QueueTable .progressing span.hold, .QueueTable .progressing i{
    display: inline-block;
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
    overflow: hidden;
    background: #d6dde3;
}
.QueueTable .progressing span.hold{
  position: relative;
  height: 20px;
  width: 125px;
  vertical-align: middle;
}
.QueueTable .progressing i{
  background: green;
}
.QueueTable span.elaps-time {
  background: #1565c0;
  color: #fff;
  padding: 0 5px;
  display: inline-block;
  margin-left: 4px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 27px;
}
@keyframes progress{
  0%{
      left: 0%;
      width: 0%;
  }
  50%{
      width: 50%;
  }
  100%{
      left: 100%;
      width: 0%;
  }
}
