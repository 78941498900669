.sidebar {
  min-width: 270px;
  max-width: 270px;
  background: #343a40 !important;
  color: #fff;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  position: relative;
}

.togglebtn {
  z-index: 1;
  position: absolute;
  top: 9px;
  left: 100%;
  transform: rotate(90deg);
  width: 28px;
  white-space: nowrap;
  margin-left: 1px;
  height: 31px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none !important;
  outline: none;
}

.list-group-item {
  border: 0px;
  color: white;
  background: transparent;
  text-align: left;
  border-bottom: 1px solid #ffffff40 !important;
}

.list-group-item span {
  padding-left: 10px;
}

.sidebar.active .list-group {
  overflow: hidden;
}

.list-group-item {
  padding: 0.35rem 1rem;
}

.sidebar.active .togglelist {
  padding: .65rem .5rem;
}

.sidebar.active .togglebtn .fa .fa-angle-double-down {
  transform: rotate(180%);
}

.sidebar.active .list-group-item {
  text-align: center;
}

.sidebar.active .list-group-item span {
  display: none;
}

.list-group-item .nav-link span, .list-group-item .nav-link i {
  color: white;
}
.sidebar .list-group-item img.logo{
  height: 1em;
  width: 1em;
}
.nav-link {
  padding: 0px;
}

.togglelist {
  padding: .65rem 1.25rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.togglelist:hover {
  cursor: pointer;
}

#sidebar .MuiSvgIcon-root {
  color: white !important;
}

.nav-link.active {
  color: #ffffff;
  background: gray
}

#toggleIcon {
  position: relative !important;
  left: -2px !important;
  bottom: 3px !important;
}

.list-group-item {
  padding: 0.35rem 1rem;
}

.nav-link .active {
  color: #ffffff;
  background: rgb(23, 97, 140);
}

li.list-group-item.active {
    background-color: #808080;
}
.collapsible-header > .analytics-head{
  display: inline !important;
  transition: 0ms;
}