.card {
  margin-left: 48px;
  margin-right: 48px;
}

.subcard {
  margin: 0 3% .5%;
  border: 1px solid #d3d3d3;
  box-shadow: 3px 3px 5px #d6dde3;
  background: #ffffff;
}

.headermsg {
  margin-bottom: 0rem;
  font-size: 18px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.col-sm-10 {
  padding-left: 2%;
}

.col-sm-10 span.info {
  background: #d6dde3;
  display: inline-block;
  text-align: center;
  padding: 0px 6px;
  color: #000;
  border-radius: 3px;
  vertical-align: middle;
  font-size: 16px;
  line-height: 25px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.col-sm-2 img {
  width: 30px;
  height: 30px;
}

.a1 {
  width: 90%;
  padding-left: 2%;
  padding-right: 2%;
}

.a2 {
  padding-left: 2%;
}

.pagination_r {
  text-align: center;
  display: inline-block;
  margin: 25px auto;
  /* margin-bottom: 100px; */
}

video {
  max-width: 100%;
}

svg g text {
  font-size: 16px !important;
}

.execution-time {
  background: #1565c0;
  color: #fff;
  padding: 0px 17px;
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  border-radius: 3px;
}

.left-align {
  text-align: left !important;
}

.right-align {
  text-align: right !important;
}

.MuiCardContent-root {
  padding: 12px !important;
}

.MuiPaginationItem-root {
  font-family: inherit !important;
  font-size: 16px !important;
}

.MuiPaginationItem-textPrimary.Mui-selected:hover,
.MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible {
  background-color: #2591d0 !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #1d7fb9 !important;
}

.MuiCard-root:last-child {
  margin-bottom: 2%;
}

.chartjs-render-monitor {
  width: 100% !important;
  height: auto !important;
}

.error_result {
  top: 10%;
  height: 20%;
  justify-content: center;
  color: blue;
  font-weight: 400;
}

#hr {
  margin-top: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1) !important;
}

#iframe {
  margin-top: 20px;
  width: 100%;
  height: 400px;
  border: 0px solid #d6dde3;
}

.downloadBtnSpan {
  float: right;
}

.downloadBtn {
  padding: 3px 6px;
  font-size: 14px;
}

.buttonProgressLoader {
  color: rgb(7, 7, 48);
  position: absolute;
  top: 50%;
  left: 27%;
  margin-top: -12px;
  margin-left: -12px;
}

.buttonErrorProgressLoader {
  color: rgb(7, 7, 48);
  position: absolute;
  top: 50%;
  right: 14%;
  margin-top: -12px;
  margin-left: -12px;
}

.reportImg {
  max-width: 100%;
  height: 30px;
  width: 30px;
}

img.desktop {
  padding: 3px 4px;
}

img.tablet {
  padding: 3px 2px;
}

img.landscape {
  transform: rotateZ(-90deg);
}

.info-holder {
  display: inline-block;
  width: calc(100% - 200px);
}

.downloadBtnSpan {
  position: absolute;
  right: 23px;
  bottom: 0;
}

.browserinfo-holder {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.resultsDetails .MuiAccordionSummary-root.Mui-expanded,
.MuiCollapse-container {
  background: #f4f5f7;
}

.resultsDetails .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid #d6dde3;
}

.resultsDetails .MuiPaper-root.MuiAccordion-root.MuiPaper-elevation1 {
  margin-bottom: 7px;
  box-shadow: 0px 2px 1px -1px #d6dde3, 0px 1px 1px 0px #d6dde3, 0px 1px 3px 0px #d6dde3;
}

.white-bg {
  background-color: #fff !important;
}

.scrollY {
  height: 70%;
  overflow-y: auto;
}

.expand-link {
  color: #007bff !important;
  cursor: pointer;
}

.resultsDetails .MuiTab-textColorInherit.Mui-selected:focus {
  outline: none;
}

.filter-buttons,
.filter-buttons:focus {
  height: 50px;
  border-color: #d6dde3 !important;
  outline: none !important;
}

.filter-buttons:hover,
.filter-buttons.active {
  background-color: #d6dde3 !important;
}

.filter-buttons.all .MuiButton-label {
  color: #000000 !important;
}

.filter-buttons.fail .MuiButton-label {
  color: #8b0000 !important;
}

.filter-buttons.error .MuiButton-label {
  color: #003c5b !important;
}

.stepstatus {
  display: inline-block;
  /* margin: 0px 17px; */
  width: 40px;
  font-size: 12px;
  font-weight: bold;
  background: #0fac31;
  border-radius: 3px;
  text-align: center;
  color: #fff;
}

.stepstatus.PASS,
.stepstatus.SKIP {
  background: #009eeb;
}

.stepstatus.FAIL {
  background: #8b0000;
}

.stepstatus.ERROR {
  background: #003c5b;
}

.stepstatus.WARNING {
  background: #d06711;
}

.timeofactivity {
  width: 100px;
  display: inline-block;
}

.steps-header span {
  font-weight: 500;
  color: black;
  padding-bottom: 10px;
}

.custom-tabs .MuiButtonBase-root:hover {
  color: #003c5b;
  background-color: #ffffff;
}

.custom-tabs .MuiTab-textColorInherit {
  color: rgb(0 60 91 / 70%);
  opacity: 1;
  background-color: #f4f5f7;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
}

.custom-tabs .Mui-selected.MuiTab-textColorInherit {
  color: #003c5b;
  background-color: #ffffff;
}

.custom-tabs .MuiTabs-indicator {
  background-color: #2591d0;
  /* color: #003c5b; */
}

.custom-accordian-details {
  background-color: #f4f5f7;
}

.small-text {
  font-size: 12px;
}

.status-green {
  color: green
}

pass,
fail {
  background: #009eeb;
  color: #fff;
  display: inline-block;
  padding: 3px;
  line-height: 8px;
  border-radius: 3px;
  font-size: 12px;
}

fail {
  background: #8b0000;
}

.assertion tr {
  border-bottom: 1px solid rgb(236, 236, 236);
}

.assertion {
  width: 100%;
}

.assertion td,
.assertion th {
  padding: 5px
}

.assertion td.PASS span,
.assertion td.FAIL span {
  display: inline-block;
  padding: 0px 3px;
  background: #009eeb;
  color: #fff;
  border-radius: 3px;
  font-weight: bold;
}

.assertion td.FAIL span {
  background: #8b0000;
}
@media screen and (max-width:1200px) {
  *{
    font-size: 12px;
  }
}