.App {

  text-align: center;
}
html, body, #root, .App, .analytics{
  height: 100%;
}
.main-wrapper{
  height: calc(100% - 98px)
}
.main-wrapper > .content{
  height: calc(100% - 30px)
}
.main-wrapper > .content > .card:nth-child(2), .main-wrapper > .content > .analytics > .card:nth-child(2){
  height: calc(100% - 65px);
  overflow-y: auto;
}
.main-wrapper > .content > .card.results:nth-child(2){
  height: calc(100% - 110px);
  overflow-y: auto;
}

body:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url('./images/pattern.png');
  /* background-size: 100%; */
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.sidebar.active {
  min-width: 80px;
  max-width: 80px;
  text-align: center;
}

.tour{
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
}
.tour svg{
  color: #2591d0 !important;
    height: 50px;
    width: 50px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.53);
}
.tour:active, .tour:focus{
  outline: none;
}

.content .card .btn-group, .content .card .btn-group-vertical{
  flex-wrap: wrap;
  max-width: 615px;
}
.content .card .btn-group .btn-wrapper, .content .card .btn-group-vertical .btn-wrapper{
  margin-bottom: 5px;
}
*::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: rgb(229, 241, 246);        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(0, 158, 235);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(229, 241, 246);   /* creates padding around scroll thumb */
}
