.searchBar {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  width: 40%;
  font-size: 16px;
  margin: 8px 0 8px 5px;
  padding: 5px 0px 0px 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
  font-family: initial;
  transition: all 0.2s ease-in-out;
}
.searchBar-text {
  background-color: transparent;
  border: none;
  outline: none;
  width: 40%;
  font-size: 16px;
  margin: 8px 0 8px 5px;
  padding: 5px 0px 0px 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
  font-family: initial;
  transition: all 0.2s ease-in-out;
}

.dropdown {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  font-size: 16px;

  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
  font-family: initial;
  transition: all 0.2s ease-in-out;
}

.searchBar-disabled {
  border: none;
  background-color: transparent;
  border: none;
  pointer-events: none;
  color: #cca9a9;
  outline: none;
  width: 50%;
  font-size: 16px;
  margin: 8px 0 8px 5px;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;
  font-family: initial;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 700px) {
  .accordion {
    width: 90%;
  }
}

.MuiDialog-root {
  position: fixed !important;
  z-index: 1300 !important;
  right: 10% !important;
  bottom: 10% !important;
  top: 0px !important;
  left: 0px !important;
}
