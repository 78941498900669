.currently-executing .header-data{
    list-style-type: none;
    margin-bottom: 0;
}
.currently-executing .header-data li{
    display: inline-block;
    padding: 10px;
    position: relative;
}
.execution-detail:before, .execution-detail:after{
    content: "";
    clear: both;
    display: table-cell;
}
.execution-detail > section{
    width: 33.3333%;
    float: left;
}
.currently-executing .header-data li .pfe-data{
    position: absolute;
    background: #fff;
    border: 1px solid #000;
    border-radius: 3px;
    padding: 5px;
    color: #000;
    z-index: 2;
}
.currently-executing .header-data li .pfe-data span{
    display: block;
    border-bottom: 1px solid #d6dde3;
    padding: 5px;
    width: 200px;
}
.currently-executing .header-data li .pfe-data span:last-child{
    border: none;
}
.pass{
    background: rgb(0, 158, 235);
    color: white;
    padding: 0px 10px !important;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.fail{
    background:rgb(139, 0, 0);
    color: white;
    padding: 0px 10px !important;
}
.error{
    background:rgb(0, 60, 91);
    color: white;
    padding: 0px 10px !important;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.currently-executing .card{
    border: none;
    margin: auto;
}